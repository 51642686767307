.gstr3b-table-container {
  font-family: 'Noto Sans', sans-serif;
  border-radius: 16px;
  background: var(--Neutral-50, #FFF);
  box-shadow: 0px 2px 12px 0px rgba(13, 10, 44, 0.15);
  padding: 20px;
  height: 402px;
  width: 440px;
}

h2 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.header-line {
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 20px;
}

.negative-delay {
  color: red;
}
