.vendor-card {
    /* background-color: #fff; 
    padding: 20px; 
    border-radius: 10px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    max-width: 200px; 
    text-align: center; 
    height: 150px;
    width: 150px;
    color: grey;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    gap: 30px; */

    display: flex;
    width: 304px;
    padding: 36.543px 0px 51.389px 0px;
    flex-direction: column;
    align-items: center;
    gap: 42.253px;
    border-radius: 16px;
    background: #FFF;
    /* box-shadow: 0px 2px 16.1px 0px rgba(217, 217, 217, 0.15); */
    box-shadow: 0px 2px 12px 0px rgba(13, 10, 44, 0.15);
    text-align: center;
    margin-left: 16px;
    /* font-family: 'Noto Sans, sans-serif' */
  }

  .card-label {
    color: #9291A5;
    /* font-family: 'Noto Sans, sans-serif'; */
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }


  .card-value {
    /* font-size: 20px;
    margin: 0;
    line-height: 1.5;
    color:blueviolet;
    font-weight: bold; */
    color: #0A8394;
    /* font-family: 'Noto Sans, sans-serif'; */
    font-size: 18px;
    font-weight: 700;
    line-height: 28px; /* 155.556% */
  }