.HotelGSTINMapping {
  display: flex;
  flex-direction: column;
  .HotelInfoContainer {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    .HotelName {
      margin-left: 4px;
    }
  }
  .SearchContainer {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}
