/* BlankCard.css */

.blank-card {
    background-color: #fff; /* White background */
    border-radius: 16px;     /* Rounded corners */
    box-shadow: 0px 2px 12px 0px rgba(13, 10, 44, 0.15);
    /* padding: 20px;       
    /* max-width: 300px;       Set a max width */
    /* min-height: 150px;      Minimum height */
    display: flex;          /* Flexbox for alignment */
    justify-content: center;
    align-items: center;    /* Center content vertically and horizontally */
  }
  