.MainComponent{
    font-family: 'Noto Sans', sans-serif;
    width: 100%;
    height: 2605px;
    padding-top: 80px;
}

.maincontent-header{
    display: flex;
    background: #FAFAFA;
    position: fixed;
    width: 100%;
    opacity: 1;
    padding-bottom: 30px;
    z-index: 2;
    /* border-bottom: 2px solid grey; */
    justify-content: space-between;
}

.body{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding-top: 130px;
}

.vendor-name-container{
    margin-top: 40px;
    margin-left: 20px;
    /* position: fixed; */
}

.vendor-name-container > *{
    margin: 0;
}


.vendor-name-label{
    /* font-size: 20px;
    color: blueviolet; */
    color: #0A8394;
    font-feature-settings: 'liga' off, 'clig' off;
    /* font-family: Inter; */
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
}

.vendor-name{
    /* font-size: 25px; */
    color: var(--Neutral-Colors-Black, #1E1B39);
    font-feature-settings: 'liga' off, 'clig' off;
    /* font-family: Inter; */
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
}


.vendor-name-details{
    display: flex;
    align-items:baseline;
    gap: 15px;
    margin-top: 5px;
}

.eligibility-status{
    /* color: orangered; */
    color: #FF3F15;
    /* font-feature-settings: 'liga' off, 'clig' off; */
    /* font-family: Inter; */
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 155.556% */
}

.invoice-type{
    align-self: center;
    color: #9291A5;
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    /* font-family: Inter; */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
}

.vendor-cards{
    display: flex;
    gap: 24px;
    /* margin-left: 40px; */
    margin-top: 28px;
    height: 185px;
    /* width: 183px; */
}

/* Container for the dropdown */
.pan-dropdown-container1 {
    margin-right: 16px; 
    margin-top: 43px;
    display: flex;
    width: 250px;
    padding: 10px;
    /* justify-content: center; */
    align-items: center;
    gap: 10px;
    border-radius: 16px;
    background: #FFF;
    /* margin: 14px; */
    /* margin-top: 10px; */
    box-shadow: 4px 5px 15px 0px rgba(204, 204, 204, 0.25);


  }
  .gstin-dropdown-container{
    margin-top: 43px;
    margin-right: 22px; 
    display: flex;
    width: 260px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 4px 5px 15px 0px rgba(204, 204, 204, 0.25);

  }
  
  /* Label styling */
.dropdown-label {
  display: block; /* Make the label a block element */
  font-size: 0.8rem; /* Font size for the label */
  /* font-weight: bold; Bold label text */
  padding-top: 10px;
}

/* Dropdown styling */
.dropdown {
  width: 200px; /* Full width of the container */
  height: 40px;
  padding: 10px; /* Padding inside the dropdown */
  border: none; /* Border color for dropdown */
  border-radius: 16px; /* Rounded corners for dropdown */
  background-color: #e9f1ff; /* White background for dropdown */
  font-size: 1rem; /* Font size for dropdown */
}
.vertical-cards-1{
  display: flex;
  flex-direction: column;
  gap:20px;
  margin-left: 20px;
}
#large-card{
  min-width: 815px;
  height: 600px;
}

.small-card{
  height: 650px;
  width: 750px;
  /* padding: 0;
  margin: 0; */
}
/* .meter-card{
  height: 650px;
  width: 750px;
} */
.gaugemeter{
  padding: 20px;
} 

.top-cards{
  display: flex;
  gap: 20px;}

#gaugechart{
  /* display: flex; */
  width: 400px;
  height: 279px;
  /* padding: 37px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0; */
  border: solid 1px;
}
.gauge-svg{
  height: 300px;
  width: 350px;
}

#map-chart{
  display: flex;
  padding: 20px 34px 19.515px 55.579px;
  /* justify-content: flex-end; */
  align-items: center;
  /* gap: 81px; */
  border-radius: 16px;
  background: #FFF;
  box-shadow: 4px 5px 15px 0px rgba(204, 204, 204, 0.25);
  height: 800px;
  width: 900px;
  margin: 0;
  /* border: solid 1px; */
}

.side-cards{
  width: 430px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.card-group-2{
  display: flex;
  gap: 24px;
  width: 1367px;
  /* margin: 24px; */
}
.card-group-3{
  display: flex;
  gap: 20px;
  margin-left: 20px;
  width: 1367px;
}
#liability-chart{
  width: 430px;
  height: 440px;
  flex-shrink: 0;
}
#current-score{
  flex: 4
}

#filing-data-card{
  /* flex:6;
  display: flex;
  width: 600px;
  height: 374px;
  padding: 30px 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px; */
  /* background-color: #FF3F15; */
  flex: 6;
  width: 544px;
  height: 400px;
  flex-shrink: 0;
  background-color: white;
}

#gstr31-filing-table{ 
  width: 544px;
  height: 400px;
  flex-shrink: 0;
  background-color: #0a0724;
}



/* #filing-data-card{
  width: 750px;

} */


/* reusable */

.remove-margin{
    margin: 0;
}

.color-grey{
    color: grey;
}

.register_hotel {
  position: absolute;
  right: 20px;
  top: 20px;
  background-color: #1ABC9C;
  border: none;
  color: white;
  border-radius: 5px;
  padding: 10px;
  width: 150px;
}
#filing-data-card{
  height: 450px;
  width: 780px;
}

