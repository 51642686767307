.HotelSearchContainer {
  position: relative;

  .SearchBox {
    width: 600px;
    height: 64px;
    background-color: #f6f6f6;
    border-radius: 12px;
    input {
      border: none;
      background-color: #f6f6f6;
      width: 100%;
      height: 100%;
    }
  }
  small {
    font-size: 12px;
    margin-top: 2px;
    b {
      color: #007cc2;
      text-decoration: underline;
    }
  }
  .dropdown {
    position: absolute;
    top: 64px;
    left: 0;
    width: 600px;
    border-radius: 4px;
    background-color: white;
    z-index: 1000;
    height: 64px;
    max-height: 300px;
    overflow-y: auto;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 0;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  .dropdown .LoadingContainer {
    padding: 10px;
  }

  .dropdown-item {
    cursor: pointer;
    border-bottom: 1px solid #e1e1e1;
    display: flex;
    align-items: center;
    padding: 6px;
    box-sizing: border-box;
    img {
      width: 32px;
    }
    .HotelInfo {
      display: flex;
      flex-direction: column;
      margin-left: 6px;

      div {
        font-size: 16px;
        font-weight: 600;
        color: #555555;
      }
      span {
        background: #f6f6f6;
        border-radius: 25px;
        font-size: 12px;
        text-align: center;
        margin: 3px 0;
      }
      small {
        font-size: 12px;
        color: #555555d1;
        margin-top: 0 !important;
      }
    }
    img {
      margin-right: 6px;
    }
  }

  .dropdown-item:last-child {
    border-bottom: none;
  }

  .dropdown-item:hover {
    background-color: #f0f0f0;
  }
  .no-results {
    font-size: 12px;
    color: #cccccc;
    b {
      color: #007cc2;
      text-decoration: underline;
      margin-left: 4px;
      cursor: pointer;
    }
  }
}
