.HotelBookingPage {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  .HeaderContainer {
    height: 100px;
    width: 100%;
  }
  .MainContainer {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 5%;
    .HotelInfoContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 66px;

      .Left {
        .HotelInfo {
          display: flex;
          align-items: center;
          h2 {
            font-size: 30px;
            font-weight: 500;
            margin: 0;
            padding: 0;
            margin-left: 4px;
          }
        }
        .HotelMetaInfoContainer {
          margin-top: 8px;
          display: flex;
          align-items: center;
          .MetaData {
            display: flex;
            align-items: center;
            p {
              margin: 0;
              margin-left: 6px;
              text-decoration: underline;
              color: #6b7280;
              font-weight: 500;
            }
          }
        }
      }
      .Right {
      }
    }
    .TableContainer {
      flex: 1;
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      .SearchContainer {
        height: 44px;
      }
      .ag-theme-alpine {
        height: 100%;
        flex: 1;
      }
    }
  }
}
