.invoiceform-container {
  position: relative; /* Ensure child absolute positioned elements are relative to here */
  display: flex;
  flex-direction: column;
  padding-left: 5%;
  padding-top: 3%;
  font-family: "Noto Sans";
  background-color: #ffffff;
  height: 100vh;
  overflow: hidden; /* Prevent scrolling */
  box-sizing: border-box;
}

.invoiceform-booking-header {
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 2vh;
}

.invoiceform-booking-status {
  font-size: 1rem;
  margin-bottom: 2vh;
}

.invoiceform-status-pending {
  color: red;
}

.invoiceform-main-grid {
  display: flex;
  gap: 2vw;
  height: 100vh;
}

.invoiceform-left-section {
  width: 60%;
  box-sizing: border-box;
}

.invoiceform-right-section {
  width: 40%;
  display: flex;
  box-sizing: border-box;
  height: 100vh;
}

.invoiceform-details-grid {
  display: flex;
  flex-direction: row;
  gap: 0.700vh;
  overflow-x: auto;
}

.invoiceform-detail-box {
  border-radius: 8px;
  flex: 1;
}

.invoiceform-head {
  margin-bottom: 1rem;
}

.invoiceform-row-details, 
.invoiceform-row-details1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.750rem;
  margin-bottom: 1vh;
}

.invoiceform-label {
  width: 150px;
  font-weight: bold;
}

.invoiceform-total {
  display: flex;
  align-items: center;
  margin-top: 2vh;
  justify-content: space-between;
}

.invoiceform-total span {
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.invoiceform-actions {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.invoiceform-btn-cancel, 
.invoiceform-btn-confirm {
  padding: 8px 12px;
  font-size: 0.700rem;
  border-radius: 5px;
}

.invoiceform-btn-cancel {
  background-color: white;
  border: 1px solid #007CC2;
  color: #007CC2;
}

.invoiceform-btn-confirm {
  background-color: #007CC2;
  border: none;
  color: white;
}

.invoiceform {
  text-align: center;
  background-color: #F3F3F3;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.invoiceform-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 5vh;
  table-layout: fixed;
}

.invoiceform-table th, .invoiceform-table td {
  padding: 1vh;
  border-right: 1px solid #ddd;
  text-align: left;
  font-size: 0.875rem;
}

.invoiceform-table th {
  font-weight: bold;
  text-align: center;
}

.invoiceform-table td:nth-child(2), 
.invoiceform-table td:nth-child(6) {
  text-align: right;
}

.invoiceform-table th:last-child, 
.invoiceform-table td:last-child {
  border-right: none;
}

.invoiceform-table th:first-child, 
.invoiceform-table td:first-child {
  width: 30%;
}

.blurred {
  filter: blur(5px);
  pointer-events: none; /* Disable interaction with blurred items */
}

.invoiceform-upload-box {
  top: 0;
  left: 0;
  width: 100vw; /* Full viewport width */
  border: 15px solid #EDEFF5; /* Maintain the border */
  text-align: center;
  background-color: #f9f9f9;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  height: 80vh;
}

.invoiceform {
  text-align: center;
  background-color: #F3F3F3;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.invoice-img {
  max-width: 80%; /* Ensures the image doesn't overflow horizontally */
  height: auto; /* Maintain aspect ratio */
  margin-top: 15px;
}

.invoice-msg, 
.loading-spinner {
  margin-top: auto;
}

.custom-modal {
  display: flex; /* Ensure the modal container is a flexbox to center the content */
  justify-content: center;
  align-items: center;
  animation: popUpFromCenter 0.3s ease-out; /* Apply the pop-up animation */
  transform-origin: center; 
}


.additional-details {
  margin-top: 2vh;
  padding: 1vh;
  background-color: #f7f7f7;
  border-radius: 8px;
}

.additional-text {
  font-size: 0.600rem;
  color: #007CC2;
  margin-bottom: 1vh;
}

.register-button {
  background-color: black;
  color: white;
  border: none;
  padding: 0.400rem;
  font-size: 0.750rem;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
}

.register-button:hover {
  background-color: #005f99;
}

@media (max-width: 768px) {
  .invoiceform-main-grid {
    flex-direction: column;
    height: auto;
  }

  .invoiceform-left-section, 
  .invoiceform-right-section {
    width: 100%;
    order: 1;
  }

  .invoiceform-booking-header, 
  .invoiceform-total span {
    text-align: center;
  }

  .invoiceform-btn-cancel, 
  .invoiceform-btn-confirm {
    width: 100%;
    margin-bottom: 1vh;
  }
}