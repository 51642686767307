.client-section {
    text-align: center;
    padding: 50px 100px;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
  }
  
  .client-section h2 {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .client-section p {
    color: #5a5a5a;
    font-size: 16px;
    margin-bottom: 30px;
    color: #007CC2;
  }
  
  .client-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    margin-bottom: 60px;
    margin-top: 40px;
  }
  
  .client-card {
    width: 15%;
    height: 50%;
    padding: 50px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
  }
  
  .client-card:hover {
    transform: translateY(-5px);
  }
  
  .client-svg {
    max-width: 50%;
    max-height: 50%;
  }
  
  .join-now-button {
    background-color: #007CC2;
    color: white;
    border: none;
    padding: 10px 30px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s;
  }
  
  .join-now-button:hover {
    background-color: #007CC2;
  }
  