.container {
    display: flex;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    padding: 20px;
    height: 380px;
    width: 400px;
    overflow-y: auto; /* Enable vertical scrolling */
    scrollbar-width: none; /* For Firefox */
  }
  
  .ag-theme-alpine .ag-header,
  .ag-theme-alpine .ag-header-cell,
  .ag-theme-alpine .ag-header-row,
  .ag-theme-alpine .ag-cell,
  .ag-theme-alpine .ag-row {
    border: none !important; /* Remove all borders */
  }
  
  .ag-theme-alpine .ag-row .ag-cell:first-child {
    font-weight: 600; /* Make the first column bold */
    color: var(--Neutral-900, #2C2C30);
  }
  
  .ag-theme-alpine .ag-header-cell {
    background-color: #fff; /* Match header background color */
    font-weight: 600;
    color: var(--Neutral-900, #2C2C30);
  }
  

  .ag-grid-container {
    width: 100%; /* Optional: set width if needed */
  }

/* .ag-root-wrapper {
  height:350px
} */