.clearfix{
  overflow-y: hidden;
}
.sec1-mainheading{
    color: #222;
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    line-height: 129.31%;
    margin-bottom: 10px;
}
.sec1-img-container{
  width: 50%;
}

.sec5-mainheading{
    color: white;
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    line-height: 129.31%;
}
.sec2-mainheading{
  text-align: center;
  margin-bottom: 80px;
  color: black;
}
.sec1-span{
    color: #007CC2;
}
.sec1-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 100px;
}
.sec1-des{
color: #555;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-bottom: 10px;
width: 70%;
}
.sec1-des1{
  color: #007CC2;
  font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-bottom: 10px;
width: 70%;
margin-top: 40px;
}
.section1-inputSection {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.section1-input {
    padding: 10px;
    font-size: 16px;
    width: 70% !important;
    margin-right: 10px;
    border-radius: 10px !important;
    border: 1px solid #999;
    height: 40px;
    margin-bottom: 10px;
    background-color: #ffffff;
}
.section1-button {
    padding: 10px 20px;
    background-color: #007CC2;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 16px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none !important;
}
.checkscore-btn{
  padding: 10px 20px;
  background-color: #007CC2;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.sec1-img{
    width: 100%;
}
.sec2-img{
    width: 80%;
    border-radius: 10px;
}
.sec2-img1{
  width: 70%;
  border-radius: 10px;
}
.img-sec3{
    width: 80%;
    border-radius: 10px;

}
.sec2-row{
    width: 100%;
    display: flex;
}
.fixed-top{
    background: #FFF;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.08);
    width: 100%;
    height: 11vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media (min-width: 1000px){
.main-navigation{
    margin-left: 50px;
}
.main-navigation2{
    margin-left: 400px;
}
}

.register-button1 {
    background-color: #007CC2;
    color: #fff;
    border: none;
    padding: 8px 25px;
    margin-left: 30px;
    font-size: 15px;
    border-radius: 10px;
  }
  .register-button2{
    background-color: #007CC2;
    color: #fff;
    border: none;
    padding: 8px 25px;
    font-size: 15px;
    border-radius: 10px;
    margin-top: 20px;
    
  }
  .login-button{
    margin-left: 10px;
    background-color: transparent;
    color: #007CC2;
    border: solid 1px ;
    padding: 7px 25px;
    margin-left: 30px;
    border-radius: 10px;
    font-size: 15px;
  }
  .contact-btn{
    background-color: #007CC2 !important;
    color: #fff;
    border: none;
    padding: 8px 25px !important;
    margin-left: 30px;
    font-size: 15px;
    border-radius: 10px;

  }

.sec2-des{
    color: #888;
font-size: 20px;
font-style: normal;
font-weight: 400;
}
.sec2-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 100px;
}
.sec2-count{
    color: #007CC2;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
}
.sec3-des{
    color: #888;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
}
.sec3-img{
    border-radius: 10px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.row-section{
  width: 100%;
}
.sec3-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    padding: 0px 100px;  
}
.sec3-container1{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 0px 100px;  
}
.dtr-section{
  background-color: #ffffff;
}
.sec3-left{
    display: flex;
    justify-content: center;
    align-items: center;
}
.sec3left-div{
  width: 70%;
}
.sec4-left{
  display: flex;
  justify-content: center;
  align-items: center;
}
.sec4left-div{
width: 70%;
}

.sec4-des{
    color: #888;
    font-size: 20px;
    font-weight: 400;
}
.sec4-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    padding: 0px 100px;
}
.sec4-img-div{
    display: flex;
    justify-content: center;
    align-items: center;
}
.checkscorebtn{
    background-color: #007CC2;
    border: none;
}
.sec6-img1{
    width: 45%;
}
.sec6-img{
    width: 70%;
}
.sec6-heading{
    margin-bottom: 70px;
    color: #222;
font-size: 60px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-align: center;
}
.sec6-logos{
    width: 80%;
}
.sec6-left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.sec6-right{
    width: 50%;
}
.sec6-container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 100px;
}

.sec5-container{
    padding: 0px 100px;
}
  

.footerlistdiv{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.footerlist{
    display: flex;
    gap: 30px;
    list-style: none;
    font-size: 15px;
}
.footerfink{
    display: flex;
    gap: 4px;
    width: 100%;
}
@media (max-width: 800px){
.footerlist{
    display: flex;
    flex-direction: column;

}
.sec6-img{
    width: 100%;
}
.sec6-right{
    width: 100%;
}
.sec6-logos{
    width: 100%;
}
.sec1-img-container{
  width: 100%;
}
}
@media (min-width: 1900px){
 
}


/* Styles for mobile header */
/* Basic styles for the navbar */
.navbar-center a {
    text-decoration: none;
    color: #888;
    font-size: 15px;
  }

  /* Active link state - this will apply the color change */
  .navbar-center a.active {
    color: #007CC2; /* Change color on selection */
    font-weight: bold; /* Make it bold when active */
  }
  .navbar-center{
    display: flex;
  }

  /* Styles for mobile menu links */
  .mobile-menu a {
    display: block;
    padding: 10px 0;
    text-decoration: none;
    color: #888; /* Default color */
  }

  /* Active state for mobile menu */
  .mobile-menu a.active {
    color: #007CC2;
    font-weight: bold;
  }

  /* Mobile header and other styling as before */
  .mobile-header {
    display: none;
    background-color: #fff;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    justify-content: space-between;
    align-items: center;
  }

  .mobile-header-left img {
    width: 120px;
  }

  .hamburger-button {
    background: none;
    border: none;
    color: #007CC2;
    cursor: pointer;
    font-size: 24px;
  }

  .menu-right button {
    margin-top: 10px;
  }
  .menu-right{
    padding-right: 30px;
  }

  /* Styles for larger screen */
  .navbar {
    display: flex;
    justify-content: space-between;
    padding: 15px 100px;
    background-color: #fff;
    width: 100%;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    height: 10vh;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.08);

  }
  .navbar-right button {
    margin-left: 10px;
  }
  .hotellogo{
    width: 20%;
  }
  .navbar-left{
    display: flex;
    align-items: center;
    width: 50%;
    gap: 80px;
  }
  .sec5-cardsdiv{
    width: 100%;
    display: flex;
    
    justify-content: space-between;
  }

 /* Fixed position for mobile menu when active */
.mobile-menu {
    background-color: #fff;
    padding: 15px;
    position: fixed; /* Ensure it's fixed when opened */
    top: 60px;
    left: 0;
    right: 0;
    z-index: 9999;
    border-top: 1px solid #ddd;
    display: none; /* Hidden initially */
    text-align: center;
}
.reg-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #007bc7;
    color: white;
    font-family: Arial, sans-serif;
    height: 250px;
  }
  .contact-header{
    color: #222;
text-align: center;
font-family: "Noto Sans";
font-size: 40px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }

  .reg-heading {
    font-size: 24px;
    text-align: center;
    margin-bottom: 30px;
    width: 30%;
  }

  .reg-button {
    background-color: white;
    color: #007bc7;
    padding: 15px 50px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
    border: none;
  }
  .footer-container{
    padding: 0px 100px;
  }
/* Responsive styles */
@media (max-width: 900px) {
    .navbar {
        display: none; /* Hide the navbar on mobile */
    }

    .mobile-header {
        display: flex; /* Show the mobile header */
    }
    .sec1-container {
        padding: 0px 10px;
    }
    .sec5-container,.sec3-container,.sec4-container,.sec6-container,.sec2-container,.footer-container{
        padding: 0px 10px;
    }
    .sec2-row{
        display: flex;
        flex-direction: column;
    }
    .sec5-cardsdiv{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .sec5-card2,.sec5-cards1,.sec5-cards3{
        width: 100%;
    }
    .reg-heading {
      font-size: 24px;
      text-align: center;
      margin-bottom: 20px;
      width: 100%;
    }
    .question-div{
      padding-left: 100px;
    }
    
}

@media (min-width: 901px) {
    /* Reset mobile menu when switching to larger screens */
    .mobile-menu {
        display: none !important; /* Ensure it's hidden on large screens */
        position: relative; /* Remove the fixed position on large screens */
    }
}



.sec5-cardsdiv {
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  
  .carousel-track {
    display: flex;
    animation: scroll-left 15s linear infinite; /* Adjust speed as needed */
  }
  
  .sec5-card {
    flex: 0 0 24%; /* Keep the width as per your original style */
    background: rgba(191, 202, 208, 0.30);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08);
    backdrop-filter: blur(3px);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    margin-right: 1%; /* Optional: Add spacing between the cards */
  }
  
  .sec5-img {
    width: 50%;
  }
  
  .sec5-img1 {
    width: 35%;
  }
  .sec5-img3 {
    width: 60%;
  }
  .sec5-img4 {
    width: 45%;
  }
  .sec5-img2 {
    width: 70%;
  }
  
  /* Keyframes for smooth infinite scrolling */
  @keyframes scroll-left {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  


.contactus-form {
    width: 60%;
}

.contactus-form h2 {
    text-align: left;
    margin-bottom: 20px;
}

.contactus-form label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
}

.contactus-form input[type="text"],
.contactus-form input[type="email"] {
  border-radius: 10px;
  border: 1px solid #999;
  background: #FFF;
  display: flex;
  align-items: center;
  align-self: stretch;
  width: 90%;
  height: 50px;
  margin-bottom: 20px;
  padding-left: 20px;
}
.contactus-input{
  border-radius: 10px;
  border: 1px solid #999;
  background: #FFF;
  display: flex;
  align-items: center;
  align-self: stretch;
  width: 90% !important;
  margin-bottom: 20px;
  padding-left: 20px;
}
.sec4-btn{
  color: #007CC2;
font-family: "Noto Sans";
border-radius: 12px;
border-radius: 12px;
border: 1px solid #007CC2;
padding: 10px 20px;
justify-content: center;
align-items: center;
background-color: #fff;

}

.contactus-form textarea {
    resize: none;
}


.contactus-form button {
  border-radius: 8px;
background: #007CC2;
display: flex;
padding: 10px 36px;
justify-content: center;
align-items: center;
gap: 10px;
border: none;
color: white;
}

.contactus-form button:hover {
    background-color: #0056b3;
}
.qf-right{
  display: flex;
  justify-content: center;
}
.qf-left{
  display: flex;
  justify-content: center;
}
.qf-container{
  width: 100%;
  padding: 0px 100px;
}




.hotel-search {
  position: relative; /* Position relative for dropdown placement */
  width: 100%; /* Ensure the container takes full width */
}

.section1-input {
  width: 100%; /* Full width for the input */
  padding: 10px; /* Padding for better spacing */
  border: 1px solid #ccc; /* Light border */
  border-radius: 4px; /* Rounded corners */
  font-size: 16px; /* Font size */
}

/* HotelSearch.css or similar */
.dropdown-list {
  border: none;
  padding: 0;
  margin-top: -10px;
  list-style-type: none;
  position: absolute;
  width: 70%;
  z-index: 1;
  background-color: white;
}

.dropdown-list li {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #eee; /* Optional: Add a border between items */
  transition: background-color 0.2s ease; /* Smooth transition for background color */
}

.dropdown-list li:hover {
  background-color: #f0f0f0; /* Change color on hover */
}


.react-search-autocomplete__icon {
  display: none !important;
}