.TriggerHotelSingleEmail {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .SuccessContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
