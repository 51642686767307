.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 72px;
  background: #FFF;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.08);
  color: #007CC2;
  position: fixed;
  z-index: 1000;
  width: 100%;
}

.navbar-left {
  display: flex;
}

.navbar-left h1 {
  font-size: 20px;
  margin: 0;
}

.navbar-center a {
  color: #888;
  text-decoration: none;
  font-size: 14px;
  padding-left: 80px;
}

.navbar-center a:hover {
  color: #007CC2;
  font-weight: bold;
  text-decoration: none;
}

.navbar-center a.active-link {
  color: #007CC2;
  font-weight: bold;
  text-decoration: none;

}

.navbar-right .register-button {
  background-color: #007CC2;
  color: #fff;
  border: none;
  padding: 7px 25px;
  margin-left: 30px;
  border-radius: 10px;
}

.login-button {
  margin-left: 10px;
  background-color: transparent;
  color: #007CC2;
  border: solid 1px;
  padding: 10px 25px;
  margin-left: 30px;
  border-radius: 10px;
}

.navbar-right .login-button:hover,
.navbar-right .register-button:hover {
  opacity: 0.8;
}

.navbar-left h1 {
  margin: 0;
}


.navbar-right .login-button:hover,
.navbar-right .register-button:hover {
  opacity: 0.8;
}

.profileImage {
  width: 60px;
  padding-right: 30px;
}

.complete-profile-button {
  background-color: #007CC2;
  color: #fff;
  border: none;
  padding: 7px 6px;
  margin-left: 30px;
  border-radius: 10px;
}
