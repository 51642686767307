.container1 {
  width: 571px;
  padding: 20px;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 2px 12px 0px rgba(13, 10, 44, 0.15);
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  height: 450px;
  /* position: absolute;
  left: 90px */
}

.table-head {
  margin-bottom: 10px;
  text-align: left;
}

.header-line {
  border-top: 2px solid #e1e1e1;
  margin: 0 0 20px 0;
}

/* Remove borders for the ag-Grid table */
.ag-theme-alpine .ag-header,
.ag-theme-alpine .ag-header-cell,
.ag-theme-alpine .ag-header-row,
.ag-theme-alpine .ag-cell,
.ag-theme-alpine .ag-row {
  border: none !important;
}

/* Make the first column bold */
.ag-theme-alpine .ag-row .ag-cell:first-child {
  font-weight: 600;
  color: var(--Neutral-900, #2C2C30);
}

/* Adjust table header styles */
.ag-theme-alpine .ag-header-cell {
  background-color: #fff;
  font-weight: 600;
  color: #2C2C30;
}
