.HeaderNew {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  align-items: center;
  height: 72px;
  padding: 20px 100px;
  box-sizing: border-box;
  justify-content: space-between;
  .HeaderLeft {
    display: flex;
    align-items: center;
    img {
      height: 40px;
    }
    .MenuContainer {
      margin-left: 100px;
      display: flex;
      align-items: center;
      .MenuItem {
        color: #888888;

        font-size: 16px;
        font-weight: 600;
        margin-right: 62px;
      }
      .active {
        color: #007cc2;
      }
    }
  }
}
