.LandingPageHome {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  .HeaderContainer {
    height: 100px;
    width: 100%;
  }
  .MainContainer {
    flex: 1;
    position: relative;
    display: flex;

    .BackgroundImage {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.8;
      overflow: hidden;
    }
    .ContentLeft {
      width: 60%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 5% 44px;
      box-sizing: border-box;
      .HeroMeta {
        div {
          font-size: 58px;
          font-weight: 600;
          line-height: normal;
        }
      }

      .HeroDescription {
        font-size: 20px;
        color: #667085;
        margin-top: 8px;
      }

      .HelpSectionContainer {
        margin-top: 24px;
        h5 {
          font-weight: 600;
          font-size: 20px;
        }
        .HelpItem {
          display: flex;
          align-items: center;
          font-size: 16px;
          margin-bottom: 12px;
        }
      }
    }

    .ContentRight {
      position: relative;
      width: 40%;
      height: 60vh; /* 100vh minus 20% padding top and bottom = 60vh */
      padding: 20% 0; /* 20% padding from top and bottom */
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      .EntityLogoContainer {
        position: relative;
        width: 100%;
        height: 60vh;
        padding: 10% 0; /* 20% padding from top and bottom */
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden; /* Ensures images don't go out of the container */

        .logo {
          position: absolute;
          max-width: 180px;
          opacity: 0;
          animation: fadeInMove 1s ease forwards;
          width: 120px;
        }

        @keyframes fadeInMove {
          0% {
            transform: translate(0, 0) scale(0);
            opacity: 0;
          }
          100% {
            transform: translate(0, 0) scale(1);
            opacity: 1;
          }
        }

        /* Adjust the positions for closer placement */
        .logo:nth-child(1) {
          top: 15%;
          left: 5%;
          animation-delay: 0.2s;
          width: 130px;
        }

        .logo:nth-child(2) {
          top: 25%;
          left: 34%;
          animation-delay: 0.4s;
          width: 160px;
        }

        .logo:nth-child(3) {
          top: 35%;
          left: 0%;
          animation-delay: 0.6s;
          width: 160px;
        }

        .logo:nth-child(4) {
          top: 20%;
          right: 10%;
          animation-delay: 0.8s;
          width: 140px;
        }

        .logo:nth-child(5) {
          top: 40%;
          left: 60%;
          animation-delay: 1s;
          width: 150px;
        }

        .logo:nth-child(6) {
          top: 50%;
          right: 40%;
          animation-delay: 1.2s;
          width: 130px;
        }

        .logo:nth-child(7) {
          top: 60%;
          left: 10%;
          animation-delay: 1.4s;
          width: 140px;
        }

        .logo:nth-child(8) {
          top: 70%;
          left: 40%;
          animation-delay: 1.6s;
          width: 150px;
        }

        .logo:nth-child(9) {
          top: 65%;
          right: 20%;
          animation-delay: 1.8s;
        }
      }
    }
  }
}
