@media (max-width: 992px) {
.container { width: 100%; max-width: 100%; }
#dtr-header-global, #dtr-header-global.header-fixed { display: none !important; }
.dtr-responsive-header, .slicknav_menu, #dtr-menu-button { display: block !important; }
/*== layout ==*/
.dtr-sidebar { width: 100%; max-width: 100%; min-width: 100%; }
.dtr-has-right-sidebar, .dtr-has-left-sidebar { width: 100%; display: flex; flex-direction: column; }
.dtr-has-right-sidebar .dtr-main { padding-right: 0; }
.dtr-has-left-sidebar .dtr-main { padding-left: 0; }
/*== forms ==*/
.dtr-form-row, .dtr-form-row-2col, .dtr-form-row-3col, .dtr-form-row-unequal, .dtr-form-row-2col-unequal { margin-right: 0; margin-left: 0; }
.dtr-form-column { position: relative; min-height: 1px; padding-right: 0; padding-left: 0; width: 100%; float: none; }
.dtr-form-row-2col .dtr-form-column, .dtr-form-row-3col .dtr-form-column, .dtr-form-row-unequal .dtr-form-column, .dtr-form-row-unequal .dtr-form-column:last-child, .dtr-form-row-2col-unequal .dtr-form-column, .dtr-form-row-2col-unequal .dtr-form-column:last-child { width: 100%; }
.main-navigation2{
    margin-left: 10px;
}
}
@media (max-width: 767px) {
.small-device-space { margin: 25px 0 !important; }
.small-device-center { text-align: center; }
/*== header ==*/
.dtr-responsive-header { text-align: center; }
.header-with-slick-menu.dtr-responsive-header { text-align: left; }
.dtr-responsive-header-left { width: 100%; }
/*== footer ==*/
.dtr-copyright .text-end  { text-align: left !important; }
#take-to-top.dtr-fade-scroll, #take-to-top::before { display: none !important; }
.dtr-pricing { margin-bottom: 30px; }
.dtr-pricing-focused { margin-top: 30px; }
.main-navigation2{
    margin-left: 10px;
}
}
@media (max-width:576px) {
/* utilities for small screen */
/*== margins ==*/
.dtr-sm-m-auto { margin: 0 auto !important }
.dtr-sm-m-0 { margin: 0 !important }
.dtr-sm-mt-0, .dtr-sm-my-0 { margin-top: 0 !important }
.dtr-sm-mr-0, .dtr-sm-mx-0 { margin-right: 0 !important }
.dtr-sm-mb-0, .dtr-sm-my-0 { margin-bottom: 0 !important }
.dtr-sm-ml-0, .dtr-sm-mx-0 { margin-left: 0 !important }
.dtr-sm-m-5 { margin: 5px !important }
.dtr-sm-mt-5, .dtr-sm-my-5 { margin-top: 5px !important }
.dtr-sm-mr-5, .dtr-sm-mx-5 { margin-right: 5px !important }
.dtr-sm-mb-5, .dtr-sm-my-5 { margin-bottom: 5px !important }
.dtr-sm-ml-5, .dtr-sm-mx-5 { margin-left: 5px !important }
.dtr-sm-m-10 { margin: 10px }
.dtr-sm-mt-10, .dtr-sm-my-10 { margin-top: 10px !important }
.dtr-sm-mr-10, .dtr-sm-mx-10 { margin-right: 10px !important }
.dtr-sm-mb-10, .dtr-sm-my-10 { margin-bottom: 10px !important }
.dtr-sm-ml-10, .dtr-sm-mx-10 { margin-left: 10px !important }
.dtr-sm-m-15 { margin: 15px }
.dtr-sm-mt-15, .dtr-sm-my-15 { margin-top: 15px !important }
.dtr-sm-mr-15, .dtr-sm-mx-15 { margin-right: 15px !important }
.dtr-sm-mb-15, .dtr-sm-my-15 { margin-bottom: 15px !important }
.dtr-sm-ml-15, .dtr-sm-mx-15 { margin-left: 15px !important }
.dtr-sm-m-20 { margin: 20px !important }
.dtr-sm-mt-20, .dtr-sm-my-20 { margin-top: 20px !important }
.dtr-sm-mr-20, .dtr-sm-mx-20 { margin-right: 20px !important }
.dtr-sm-mb-20, .dtr-sm-my-20 { margin-bottom: 20px !important }
.dtr-sm-ml-20, .dtr-sm-mx-20 { margin-left: 20px !important }
.dtr-sm-m-30 { margin: 30px !important }
.dtr-sm-mt-30, .dtr-sm-my-30 { margin-top: 30px !important }
.dtr-sm-mr-30, .dtr-sm-mx-30 { margin-right: 30px !important }
.dtr-sm-mb-30, .dtr-sm-my-30 { margin-bottom: 30px !important }
.dtr-sm-ml-30, .dtr-sm-mx-30 { margin-left: 30px !important }
.dtr-sm-m-40 { margin: 40px !important }
.dtr-sm-mt-40, .dtr-sm-my-40 { margin-top: 40px !important }
.dtr-sm-mr-40, .dtr-sm-mx-40 { margin-right: 40px !important }
.dtr-sm-mb-40, .dtr-sm-my-40 { margin-bottom: 40px !important }
.dtr-sm-ml-40, .dtr-sm-mx-40 { margin-left: 40px !important }
.dtr-sm-m-50 { margin: 50px !important }
.dtr-sm-mt-50, .dtr-sm-my-50 { margin-top: 50px !important }
.dtr-sm-mr-50, .dtr-sm-mx-50 { margin-right: 50px !important }
.dtr-sm-mb-50, .dtr-sm-my-50 { margin-bottom: 50px !important }
.dtr-sm-ml-50, .dtr-sm-mx-50 { margin-left: 50px !important }
.dtr-sm-m-60 { margin: 60px !important }
.dtr-sm-mt-60, .dtr-sm-my-60 { margin-top: 60px !important }
.dtr-sm-mr-60, .dtr-sm-mx-60 { margin-right: 60px !important }
.dtr-sm-mb-60, .dtr-sm-my-60 { margin-bottom: 60px !important }
.dtr-sm-ml-60, .dtr-sm-mx-60 { margin-left: 60px !important }
.dtr-sm-m-70 { margin: 70px !important }
.dtr-sm-mt-70, .dtr-sm-my-70 { margin-top: 70px !important }
.dtr-sm-mr-70, .dtr-sm-mx-70 { margin-right: 70px !important }
.dtr-sm-mb-70, .dtr-sm-my-70 { margin-bottom: 70px !important }
.dtr-sm-ml-70, .dtr-sm-mx-70 { margin-left: 70px !important }
.dtr-sm-m-80 { margin: 80px !important }
.dtr-sm-mt-80, .dtr-sm-my-80 { margin-top: 80px !important }
.dtr-sm-mr-80, .dtr-sm-mx-80 { margin-right: 80px !important }
.dtr-sm-mb-80, .dtr-sm-my-80 { margin-bottom: 80px !important }
.dtr-sm-ml-80, .dtr-sm-mx-80 { margin-left: 80px !important }
.dtr-sm-m-90 { margin: 90px !important }
.dtr-sm-mt-90, .dtr-sm-my-90 { margin-top: 90px !important }
.dtr-sm-mr-90, .dtr-sm-mx-90 { margin-right: 90px !important }
.dtr-sm-mb-90, .dtr-sm-my-90 { margin-bottom: 90px !important }
.dtr-sm-ml-90, .dtr-sm-mx-90 { margin-left: 90px !important }
.dtr-sm-m-100 { margin: 100px !important }
.dtr-sm-mt-100, .dtr-sm-my-100 { margin-top: 100px !important }
.dtr-sm-mr-100, .dtr-sm-mx-100 { margin-right: 100px !important }
.dtr-sm-mb-100, .dtr-sm-my-100 { margin-bottom: 100px !important }
.dtr-sm-ml-100, .dtr-sm-mx-100 { margin-left: 100px !important }
/* minus margins */
.dtr-sm-mb-minus10 { margin-bottom: -10px !important; }
.dtr-sm-mb-minus20 { margin-bottom: -20px !important; }
.dtr-sm-mb-minus30 { margin-bottom: -30px !important; }
.dtr-sm-mb-minus40 { margin-bottom: -40px !important; }
.dtr-sm-mb-minus50 { margin-bottom: -50px !important; }
.dtr-sm-mb-minus60 { margin-bottom: -60px !important; }
.dtr-sm-mb-minus70 { margin-bottom: -70px !important; }
.dtr-sm-mb-minus80 { margin-bottom: -80px !important; }
.dtr-sm-mb-minus90 { margin-bottom: -90px !important; }
.dtr-sm-mb-minus100 { margin-bottom: -100px !important; }
.dtr-sm-mb-minus110 { margin-bottom: -110px !important; }
.dtr-sm-mb-minus120 { margin-bottom: -120px !important; }
.dtr-sm-mb-minus130 { margin-bottom: -130px !important; }
.dtr-sm-mb-minus140 { margin-bottom: -140px !important; }
.dtr-sm-mb-minus150 { margin-bottom: -150px !important; }
/*== paddings ==*/
.dtr-sm-p-0 { padding: 0 !important }
.dtr-sm-pt-0, .dtr-sm-py-0 { padding-top: 0 !important }
.dtr-sm-pr-0, .dtr-sm-px-0 { padding-right: 0 !important }
.dtr-sm-pb-0, .dtr-sm-py-0 { padding-bottom: 0 !important }
.dtr-sm-pl-0, .dtr-sm-px-0 { padding-left: 0 !important }
.dtr-sm-p-5 { padding: 5px !important }
.dtr-sm-pt-5, .dtr-sm-py-5 { padding-top: 5px !important }
.dtr-sm-pr-5, .dtr-sm-px-5 { padding-right: 5px !important }
.dtr-sm-pb-5, .dtr-sm-py-5 { padding-bottom: 5px !important }
.dtr-sm-pl-5, .dtr-sm-px-5 { padding-left: 5px !important }
.dtr-sm-p-10 { padding: 10px !important }
.dtr-sm-pt-10, .dtr-sm-py-10 { padding-top: 10px !important }
.dtr-sm-pr-10, .dtr-sm-px-10 { padding-right: 10px !important }
.dtr-sm-pb-10, .dtr-sm-py-10 { padding-bottom: 10px !important }
.dtr-sm-pl-10, .dtr-sm-px-10 { padding-left: 10px !important }
.dtr-sm-p-15 { padding: 15px !important }
.dtr-sm-pt-15, .dtr-sm-py-15 { padding-top: 15px !important }
.dtr-sm-pr-15, .dtr-sm-px-15 { padding-right: 15px !important }
.dtr-sm-pb-15, .dtr-sm-py-15 { padding-bottom: 15px !important }
.dtr-sm-pl-15, .dtr-sm-px-15 { padding-left: 15px !important }
.dtr-sm-p-20 { padding: 20px !important }
.dtr-sm-pt-20, .dtr-sm-py-20 { padding-top: 20px !important }
.dtr-sm-pr-20, .dtr-sm-px-20 { padding-right: 20px !important }
.dtr-sm-pb-20, .dtr-sm-py-20 { padding-bottom: 20px !important }
.dtr-sm-pl-20, .dtr-sm-px-20 { padding-left: 20px !important }
.dtr-sm-p-30 { padding: 30px !important }
.dtr-sm-pt-30, .dtr-sm-py-30 { padding-top: 30px !important }
.dtr-sm-pr-30, .dtr-sm-px-30 { padding-right: 30px !important }
.dtr-sm-pb-30, .dtr-sm-py-30 { padding-bottom: 30px !important }
.dtr-sm-pl-30, .dtr-sm-px-30 { padding-left: 30px !important }
.dtr-sm-p-40 { padding: 40px !important }
.dtr-sm-pt-40, .dtr-sm-py-40 { padding-top: 40px !important }
.dtr-sm-pr-40, .dtr-sm-px-40 { padding-right: 40px !important }
.dtr-sm-pb-40, .dtr-sm-py-40 { padding-bottom: 40px !important }
.dtr-sm-pl-40, .dtr-sm-px-40 { padding-left: 40px !important }
.dtr-sm-p-50 { padding: 50px !important }
.dtr-sm-pt-50, .dtr-sm-py-50 { padding-top: 50px !important }
.dtr-sm-pr-50, .dtr-sm-px-50 { padding-right: 50px !important }
.dtr-sm-pb-50, .dtr-sm-py-50 { padding-bottom: 50px !important }
.dtr-sm-pl-50, .dtr-sm-px-50 { padding-left: 50px !important }
.dtr-sm-p-60 { padding: 60px !important }
.dtr-sm-pt-60, .dtr-sm-py-60 { padding-top: 60px !important }
.dtr-sm-pr-60, .dtr-sm-px-60 { padding-right: 60px !important }
.dtr-sm-pb-60, .dtr-sm-py-60 { padding-bottom: 60px !important }
.dtr-sm-pl-60, .dtr-sm-px-60 { padding-left: 60px !important }
.dtr-sm-p-70 { padding: 70px !important }
.dtr-sm-pt-70, .dtr-sm-py-70 { padding-top: 70px !important }
.dtr-sm-pr-70, .dtr-sm-px-70 { padding-right: 70px !important }
.dtr-sm-pb-70, .dtr-sm-py-70 { padding-bottom: 70px !important }
.dtr-sm-pl-70, .dtr-sm-px-70 { padding-left: 70px !important }
.dtr-sm-p-80 { padding: 80px !important }
.dtr-sm-pt-80, .dtr-sm-py-80 { padding-top: 80px !important }
.dtr-sm-pr-80, .dtr-sm-px-80 { padding-right: 80px !important }
.dtr-sm-pb-80, .dtr-sm-py-80 { padding-bottom: 80px !important }
.dtr-sm-pl-80, .dtr-sm-px-80 { padding-left: 80px !important }
.dtr-sm-p-100 { padding: 100px !important }
.dtr-sm-p-90 { padding: 90px !important }
.dtr-sm-pt-90, .dtr-sm-py-90 { padding-top: 90px !important }
.dtr-sm-pr-90, .dtr-sm-px-90 { padding-right: 90px !important }
.dtr-sm-pb-90, .dtr-sm-py-90 { padding-bottom: 90px !important }
.dtr-sm-pl-90, .dtr-sm-px-90 { padding-left: 90px !important }
.dtr-sm-p-100 { padding: 100px !important }
.dtr-sm-pt-100, .dtr-sm-py-100 { padding-top: 100px !important }
.dtr-sm-pr-100, .dtr-sm-px-100 { padding-right: 100px !important }
.dtr-sm-pb-100, .dtr-sm-py-100 { padding-bottom: 100px !important }
.dtr-sm-pl-100, .dtr-sm-px-100 { padding-left: 100px !important }
.main-navigation2{
    margin-left: 10px;
}
}
