.HotelContactManagement {
  height: 100vh;
  .MainContainer {
    height: 100%;
  }
}

.ag-footer-wrapper {
  background-color: #fdfdfd; /* Change background color */
  border-top: 1px solid #ddd; /* Add a top border for separation */
  padding: 10px; /* Add some padding */
}

.ag-paging-panel {
  background-color: #f0f8ff; /* Different background for pagination section */
  border-top: none; /* Remove border if it repeats */
  padding: 5px; /* Adjust padding */
  display: flex; /* Ensure items are flex positioned */
  justify-content: space-between; /* Space out pagination elements */
}

.ag-status-bar {
  background-color: #f5f5f5; /* Optional: Style status bar similarly */
  padding: 5px;
  font-size: 14px; /* Make text a bit larger */
}

.right-align {
  justify-content: flex-end;
}
